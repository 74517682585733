

import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import Confetti from "react-confetti";

function ThankYouPage(props) {

    const { t, i18n } = useTranslation()


    const [isSend, setIsSend] = useState(true);

    useEffect(()=>{
        setTimeout(()=>{
            setIsSend(false)
        }, 7000)
    })

    return (<div className={'vh-100'}>
        {
            isSend &&   <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                run={isSend}
                recycle={isSend}
            />
        }
        <Section title={'Thank you for your Offer'} name={'thank-you'}/>
        <div className="col-md-3 shadow pb-2">
            {/*<img src="https://images.unsplash.com/photo-1526614180703-827d23e7c8f2" className={'img-fluid mb-3'} alt="thank-you" height={'500'}/>*/}
            <img src="/assets/logo/growth.png" className={'img-fluid mb-3'} alt="thank-you" height={'500'}/>
            <h1>{t('page.thank-you.title')}</h1>
            <p>
                {t('page.thank-you.subtitle')}
            </p>
        </div>
        <a href="/" className={'btn bg-main text-white mt-5 w-25 shadow-lg'}>{t('page.thank-you.btn.back')}</a>
        <br/><br/>
        <a href="https://account.growthukraine.com/" className={'text-secondary'}>{t('page.thank-you.btn.sig-in')}</a>
        <br/><br/>
    </div>);
}

export default ThankYouPage;
