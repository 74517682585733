import React from "react";
import styled from "styled-components";

function DoubleImageHover({ background, hoverBackground }) {
    const DoubleImageHover = styled.div`
    background: ${props => `url(${props.background}) no-repeat top center`};
    max-height: 200px;
    max-width: 200px;
    min-height: 180px;
    min-width: 100px;
    border-radius: 15px;
    background-size: cover;
    transition: background-color 2s ease-out 100ms;
    &:hover {
      background: ${props =>
        `url(${props.hoverBackground}) no-repeat top center`};
      background-size: contain;
    }
  `;

    return (
        <DoubleImageHover
            background={background}
            hoverBackground={hoverBackground}
            className="logoContent"
        />
    );
}
export default DoubleImageHover;