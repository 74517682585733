import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {BACK_END_URL} from './config';
import CardService from "../../components/card/service_card";
import Accordion from "react-bootstrap/Accordion";
import {useTranslation} from "react-i18next";

const ServiceApi = (props) => {

     const { t, i18n } = useTranslation();

     const [service, setService] = useState([]);

     const parameters = props.type === 'popular' ? '?select=popular&sort=DESC' : '';

     useEffect(()=>{
            axios.get(BACK_END_URL + 'get-services' + parameters).then(function (response){

                    const getService = response.data;
                    setService(getService.data);
                    if(props.type !== 'popular'){
                        window.localStorage.setItem("services", JSON.stringify(getService.data));
                    }
                    console.log(getService.data);
             }).catch(error => {
                     console.log(error);
             });
     }, []);


    return <>
        <div className="container-fluid d-flex  flex-wrap">
        {
            service && service.map((e, index) => <CardService key={index} data={e} type={props.type}/>)
        }
        </div>
    </>

}

export default ServiceApi