import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import CooperationForm from "./cooperation_form";
import BASIC_INFORMATION from '../service/information/basic';

import Confetti from 'react-confetti'

function FormOffer(props) {

    const { t, i18n } = useTranslation()

    const [services, setServices] = useState([]);


    // useEffect(() => {
    //
    //         let data = axios.get(BACK_END_URL + 'get-services').then(function (response){
    //             console.log(response.data);
    //             return response.data.data;
    //         }).catch(error => {
    //             console.log(error);
    //         })
    //         setServices(data);
    //         window.localStorage.setItem("services", JSON.stringify(data));
    //
    // }, [])

    return (<div className={''}>
        <div className="row d-flex flex-md-row flex-column-reverse justify-content-between align-items-start border p-md-2 p-1 rounded-4 shadow-lg pb-5">
            <div className="col-12 col-md-5 form-title p-3">
                <h5 className={'display-6 p-3 pt-4'}>
                    {t('form.title')}
                </h5>
                <div className="w-100 block text-start">
                    <div className="links text-start w-100 mt-3">
                        {/*<h3 className={''}> {t('form.phone')}</h3> <br/>*/}
                        <span className={'h4'}>
                            <a href={'tel.:' + BASIC_INFORMATION.phoneKyivstar} className={'color-main text-decoration-none'}> {BASIC_INFORMATION.phoneKyivstar}</a>
                        </span> <br/>
                        {/*<span className={'display-'}>*/}
                        {/*    <a href={'tel.:' + BASIC_INFORMATION.phoneLifecell} className={'color-main text-decoration-none'}> {BASIC_INFORMATION.phoneLifecell}</a>*/}
                        {/*</span> <br/>*/}
                        <br/>
                        {/*<h3 className={''}> {t('form.email')}</h3>*/}
                        <h4 className={'p-3 pt-0'}>
                            <a href={
                                'mailto:' + BASIC_INFORMATION.email + '?subject=Q&A-Form'
                            } className={'text-secondary text-decoration-none'}> {BASIC_INFORMATION.email}</a> <br/>
                        </h4>
                        {/*<div className="qr-code-app color-main d-none d-sm-block">*/}
                        {/*    <span className={'lead-'}><b>{t('nav.app.download')}</b></span>*/}
                        {/*    <div className="p-3">*/}
                        {/*        <img src="/assets/qr-code/me.png" alt="qr-code-app" className={'col-md-4 shadow'}/>*/}
                        {/*        <div className="col-md-4"></div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-7 form-fields p-2">
                <CooperationForm lang={i18n.language} services={services} />
            </div>
        </div>
    </div>);
}

export default FormOffer;
