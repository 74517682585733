

import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import BASIC_INFORMATION from "../service/information/basic";
import EmployeeCard from "../components/card/employee";

function PageTeam(props) {

    const { t, i18n } = useTranslation()

    return (<div>
        <Section title={''} name={'team'} child={
            <div className="d-flex flex-wrap">

                <EmployeeCard
                    index={1}
                    avatar={''}
                    position={'Founder & CEO'}
                    name={''}
                    links={''}
                />

                <EmployeeCard
                    index={1}
                    avatar={''}
                    position={'Software Developer'}
                    name={''}
                    links={''}
                />

                <EmployeeCard
                    index={1}
                    avatar={''}
                    position={'SMM & Clients'}
                    name={''}
                    links={''}
                />

                <EmployeeCard
                    index={1}
                    avatar={''}
                    position={'WEB Developer'}
                    name={''}
                    links={''}
                />

                {/*<EmployeeCard*/}
                {/*    index={1}*/}
                {/*    avatar={''}*/}
                {/*    position={'Co-founder'}*/}
                {/*    name={''}*/}
                {/*    links={''}*/}
                {/*/>*/}

                {/*<EmployeeCard*/}
                {/*    index={0}*/}
                {/*    qr={'/assets/qr-code/me.png'}*/}
                {/*    avatar={'https://scontent.fiev22-1.fna.fbcdn.net/v/t39.30808-6/323420488_696945792094607_9023996267842231255_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=xqzBO9XGgwkAX_FjeIr&_nc_ht=scontent.fiev22-1.fna&oh=00_AfDTtm7DivhrVPaJLVqkNoX3fWwwZMB6qoVzvZhSifBKRQ&oe=63D9C205'}*/}
                {/*    position={'Co-founder'}*/}
                {/*    name={'Yarosalv Lukyanchuk'}*/}
                {/*    links={['https://facebook.com/yarik.lukyanchuk/', 'https://linkedin.com/in/yaroslav-lukyanchuk-a028481ab/', 'https://instagram.com/yariklukyanchuk/', 'https://t.me/YaroslavLukyanchuk']}*/}
                {/*/>*/}

                {/*<EmployeeCard*/}
                {/*    index={1}*/}
                {/*//     avatar={''}*/}
                {/*//     position={'SEO & Auditing'}*/}
                {/*//     name={''}*/}
                {/*//     links={''}*/}
                {/*// />*/}
                {/*//*/}
                {/*// <EmployeeCard*/}
                {/*//     index={1}*/}
                {/*//     avatar={''}*/}
                {/*//     position={'Ads & SMM'}*/}
                {/*//     name={''}*/}
                {/*//     links={''}*/}
                {/*// />*/}
                {/*//*/}
                {/*// <EmployeeCard*/}
                {/*//     index={1}*/}
                {/*//     avatar={''}*/}
                {/*//     position={'Client Manager'}*/}
                {/*//     name={''}*/}
                {/*//     links={''}*/}
                {/*// />*/}
                {/*//*/}
                {/*// <EmployeeCard*/}
                {/*//     index={1}*/}
                {/*//     avatar={''}*/}
                {/*//     position={'UI&UX Designer'}*/}
                {/*//     name={''}*/}
                {/*//     links={''}*/}
                {/*// />*/}
                {/*//*/}
                {/*// <EmployeeCard*/}
                {/*//     index={1}*/}
                {/*//     avatar={''}*/}
                {/*//     position={'Front-end Developer'}*/}
                {/*//     name={''}*/}
                {/*//     links={''}*/}
                {/*// />*/}
                {/*//*/}
                {/*// <EmployeeCard*/}
                {/*//     index={1}*/}
                {/*//     avatar={''}*/}
                {/*//     position={'Back-end Developer'}*/}
                {/*//     name={''}*/}
                {/*//     links={''}*/}
                {/*// />*/}
                {/*//*/}
                {/*// <EmployeeCard*/}
                {/*//     index={1}*/}
                {/*//     avatar={''}*/}
                {/*//     position={'App Developer'}*/}
                {/*//     name={''}*/}
                {/*//     links={''}*/}
                {/*// />*/}
                {/*//*/}
                {/*// <EmployeeCard*/}
                {/*//     index={1}*/}
                {/*//     avatar={''}*/}
                {/*//     position={'DevOps Developer'}*/}
                {/*//     name={''}*/}
                {/*//     links={''}*/}
                {/*// />*/}
                {/*//*/}
                {/*// <EmployeeCard*/}
                {/*//     index={1}*/}
                {/*//     avatar={''}*/}
                {/*//     position={'Lawyer'}*/}
                {/*//     name={''}*/}
                {/*//     links={''}*/}
                {/*// />*/}
                {/*//*/}
                {/*// <EmployeeCard*/}
                {/*//     index={1}*/}
                {/*//     avatar={''}*/}
                {/*//     position={'Accountant'}*/}
                {/*//     name={''}*/}
                {/*//     links={''}*/}
                {/*// />*/}

                {/*<EmployeeCard*/}
                {/*    index={0}*/}
                {/*    qr={'/assets/qr-code/me.png'}*/}
                {/*    avatar={'https://scontent.fiev22-1.fna.fbcdn.net/v/t39.30808-6/323420488_696945792094607_9023996267842231255_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=xqzBO9XGgwkAX_FjeIr&_nc_ht=scontent.fiev22-1.fna&oh=00_AfDTtm7DivhrVPaJLVqkNoX3fWwwZMB6qoVzvZhSifBKRQ&oe=63D9C205'}*/}
                {/*    position={'Software Developer'}*/}
                {/*    name={'Yarosalv Lukyanchuk'}*/}
                {/*    links={['https://facebook.com/yarik.lukyanchuk/', 'https://linkedin.com/in/yaroslav-lukyanchuk-a028481ab/', 'https://instagram.com/yariklukyanchuk/', 'https://t.me/YaroslavLukyanchuk']}*/}
                {/*/>*/}

            </div>
        } />
    </div>);
}

export default PageTeam;
