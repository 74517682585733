import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import FormOffer from "../components/form";
import ProjectsSection from "../components/section/projects_section";

function ProjectsPage(props) {

    const { t, i18n } = useTranslation()

    return (<div>
        <Section title={''} name={'projects'} child={<ProjectsSection limit={0}/>}/>
    </div>);
}

export default ProjectsPage;
