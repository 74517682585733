import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import FormOffer from "../components/form";
import * as FaIcons from "react-icons/fa";
import BASIC_INFORMATION from '../service/information/basic';

function ContactsPage(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'pt-5'}>
       <div className={'contacts-page container text-start mt-5 pt-5 bg-white mb-5'}>
           <div className="block border-start shadow d-none d-sm-block">
               <img src="/assets/logo/growth.png" className={'img-fluid'} alt="contact-page-image"/>
           </div>

           <div className="bg-white pb-5 p-5 block">
               <h1 className={'text-secondary p-3'}>
                   {t('nav.contacts')}
               </h1>
               <br/>
               <div className="contacts bg-white text-dark p-3">
                   <a href={'tel.:' + BASIC_INFORMATION.phoneKyivstar} className={'text-secondary'}> <FaIcons.FaMobileAlt /> {BASIC_INFORMATION.phoneKyivstar}</a> <br/>
                   {/*<a href={'tel.:' + BASIC_INFORMATION.phoneLifecell} className={'text-secondary'}> <FaIcons.FaMobileAlt /> {BASIC_INFORMATION.phoneLifecell}</a> <br/>*/}
                   <br/>
                   <a href={
                       'mailto:' + BASIC_INFORMATION.email + '?subject=Q&A-Contacts'
                   } className={'text-secondary'}> <FaIcons.FaEnvelope /> {BASIC_INFORMATION.email}</a> <br/>
                   <br/>
                   <a className={'text-secondary'}>  <FaIcons.FaMapMarker /> {t('contacts.address.city')} </a> <br/>
                   {/*<a className={'text-secondary'}>{t('contacts.address.street')}</a> <br/> <br/>*/}
               </div>

               {/*<br/>*/}
               {/*<h3 className={'text-secondary p-3'}>*/}
               {/*    {t('nav.info')}*/}
               {/*</h3>*/}

               {/*<div className="contacts bg-white links text-dark">*/}
               {/*    <a href="/assets/files/growthukraine_presentation.pdf" target={'_blank'} className={'text-dark'}>*/}
               {/*        <span>{t('nav.presentation')}</span>*/}
               {/*    </a>*/}
               {/*    <a href="/team" target={'_blank'} className={'text-dark'}><span>{t('nav.team')}</span></a>*/}
               {/*    <a href="/career" target={'_blank'} className={'text-dark'}><span>{t('nav.career')}</span></a>*/}
               {/*</div>*/}

           </div>

       </div>
    </div>);
}

export default ContactsPage;
