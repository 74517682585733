import React from "react";
import {useTranslation} from "react-i18next";
import BASIC_INFORMATION from "../../service/information/basic";
import * as FaIcons from "react-icons/fa";
import DoubleImageHover from "../double-image-hover";

function EmployeeCard(props) {
    const { t, i18n } = useTranslation();
    return (
        <div className={'col-md-3 col-6 card-employee p-md-5 p-2'}>
            <div className="text-center border rounded-4 shadow">
                <div className="avatar pt-1 pb-2">

                    {
                        props.avatar === '' ?  <FaIcons.FaUser class={'color-main display-1'} /> :
                            <DoubleImageHover
                                key={props.index}
                                background={props.avatar}
                                hoverBackground={props.qr}
                            />
                    }
                </div>
                <span className="text-secondary">
                            {props.position ?? 'Position'}
                        </span>
                <h5 className={'lead'}>
                    {props.name ?? 'Name Surname'}
                </h5>

                    <div className="d-flex align-items-center justify-content-center col-md-4 col-6 m-auto pb-2">
                        <div className="link p-1 m-0">
                            <a href={props.links[0]} className={'employee'}>
                                <FaIcons.FaFacebook />
                            </a>
                        </div>
                        <div className="link p-1 m-0">
                            <a href={props.links[1]} className={'employee'}>
                                <FaIcons.FaLinkedinIn />
                            </a>
                        </div>
                        <div className="link p-1 m-0">
                            <a href={props.links[2]} className={'employee'}>
                                <FaIcons.FaInstagram />
                            </a>
                        </div>
                        <div className="link">
                            <a href={props.links[3]} className={'employee'}>
                                <FaIcons.FaTelegram />
                            </a>
                        </div>
                    </div>

            </div>
        </div>
    );

}

export default EmployeeCard;