import React, {Suspense} from 'react';
import Section from "../components/section";

const ProjectsSection = React.lazy(() => import('../components/section/projects_section'));
const SectionSupport = React.lazy(() => import('../components/section/support_section'));
const SectionAboutUs = React.lazy(() => import('../components/section/about_section'));
const SectionPopularServices = React.lazy(() => import('../components/section/popular_services_section'));
const SectionApp = React.lazy(() => import('../components/section/app_section'));
const SectionSocial = React.lazy(() => import('../components/section/social_section'));
const SectionPartners = React.lazy(() => import('../components/section/partners_section'));

function HomePage(props) {

    return (<div>
        {/*<Section title={''} name={'projects'} child={<Suspense fallback={<div>Loading...</div>}><ProjectsSection limit={6} /></Suspense>} link={'/projects'} linkName={''}/>*/}
        <Suspense fallback={<div>Loading...</div>}><SectionSupport /></Suspense>
        <Suspense fallback={<div>Loading...</div>}><SectionAboutUs /></Suspense>
        <Suspense fallback={<div>Loading...</div>}>
            <SectionPopularServices />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}><SectionApp /></Suspense>
        <Suspense fallback={<div>Loading...</div>}><SectionSocial /></Suspense>
        <Section title={''} name={'projects'} child={<Suspense fallback={<div>Loading...</div>}><ProjectsSection limit={6} /></Suspense>} link={'/projects'} linkName={''}/>
        {/*<Suspense fallback={<div>Loading...</div>}><SectionPartners /></Suspense>*/}
        {/*<Section title={t('nav.cooperation')} name={'cooperation'} child={<FormOffer />} link={'/#cooperation'} linkName={''}/>*/}
    </div>);
}

export default HomePage;
