

import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import BASIC_INFORMATION from "../service/information/basic";

function PageSupport(props) {

    const { t, i18n } = useTranslation()

    return (<div>
        <Section title={''} name={'team'} child={
            <div>
                <h2 className={'color-main display-1 pb-5'}>
                    {t('ua.support')}
                </h2>

                <div className="support">



                    <div className="row d-flex justify-content-between align-items-center">

                        <div className="support-1 col-md-6 col-12">

                            <span className={'text-secondary'}>Благодійний фонд "Сергія Притули"</span>
                            <h4 className={'p-3 pt-1 display- color-main lead'}>Charitable Foundation "Serhiy Prytula"</h4>


                            <div className="d-flex flex-wrap align-items-center">

                                <div className="col-md-5 col-12 p-2">
                                    <a href="https://prytulafoundation.org/uk/home/support_page/">
                                        <img src="/assets/qr-code/prutyla.png" alt="qr-code-prutyla" className={'img-fluid shadow w-100'}/>
                                    </a>
                                </div>

                                <div className="col-md-7 col-12 text-start p-2">

                                    <div className="p-1 pt-1 img-support">
                                        <img src="https://prytulafoundation.org/assets/logo-black-4b22647d1907efd28d4cbdf69da3051d2babcb88ef128dcd4ca8516afbe850dc.svg" alt="prytulafoundation" className={'img-fluid rounded shadow p-2'}/>
                                    </div>
                                    <div className="link">
                                        <span className={'text-secondary text-uppercase'}>{t('nav.link')}:</span>
                                        <a href="https://prytulafoundation.org/uk/home/support_page/" className={'p-1'}>https://prytulafoundation.org/</a>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="support-2 col-md-6 col-12">

                            <span className={'text-secondary'}>Повернись живим</span>
                            <h5 className={'p-3 pt-1 color-main lead'}>Come back alive</h5>

                            <div className="d-flex flex-wrap align-items-center">
                                <div className="col-md-5 col-12 p-2">
                                    <img src="/assets/qr-code/savelife.png" alt="qr-code-prutyla" className={'img-fluid shadow w-100'}/>
                                </div>

                                <div className="col-md-7 col-12 text-start p-2">

                                    <div className="p-1 pt-1 img-support">
                                        <a href="https://savelife.in.ua/donate/#donate-army-card-monthly">
                                            <img src="https://xl-static.rozetka.com.ua/assets/img/design/logos/war_banner.svg" alt="savelife" className={'bg-black rounded p-2 shadow'}/>
                                        </a>
                                    </div>
                                    <div className="link">
                                        <span className={'text-secondary text-uppercase'}>{t('nav.link')}:</span>
                                        <a href="https://savelife.in.ua/donate/#donate-army-card-monthly" className={'p-1'}>https://savelife.in.ua</a>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        }/>
    </div>);
}

export default PageSupport;
