import React from 'react';
import {useTranslation} from "react-i18next";

function Section(props) {

    const { t, i18n } = useTranslation()

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value)
    }

    return (<div>
      <div id={props.name} className={"section container p-md-5 p-3 text-start " + props.name}>
          <div className="d-flex justify-content-between">
             <div className="w-50">
                 <h3 className={'display-5'}>
                     {props.title}
                 </h3>
             </div>
              <div className="w-50 text-end">
                <a href={props.link}><label className={'color-main font-weight-bold text-uppercase'}>{props.linkName}</label></a>
              </div>
          </div>
          <div className="section__content">
              {props.child}
          </div>
      </div>
    </div>);
}

export default Section;
