
import React from 'react';
import ServiceApi from "../../service/api/services_api";

function ServiceSection(props) {

    return (<div>
        <ServiceApi type={props.type}/>
    </div>);
}

export default ServiceSection;