import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import AOS from 'aos';
import "aos/dist/aos.css";
import * as FaIcons from "react-icons/fa";

function Service_card(props) {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        AOS.init({
            duration : 2000
        });
    }, []);

    return (

            <div className={props.type === 'popular' ? "col-md-4 pb-2" : "col-md-4 pb-2"} data-aos="fade-up"
                 data-aos-duration="3000">
                <div className="p-2">
                    <div className="text-start shadow rounded-4 bg-white p-4 card-service d-flex flex-column justify-content-between">
                        <div className="mt-1 col-md-12">
                           <h2 className={'text-start'}>
                               <b dangerouslySetInnerHTML={{__html: props.data.image}} />
                               <span>{props.data.name[i18n.language === 'en' ? 'en' : 'ua']}</span>
                           </h2>
                        </div>
                        <p className={'col-md-12 text-start text-secondary h6'}>
                            {
                                props.type === 'popular' ?
                                props.data.note[i18n.language === 'en' ? 'en' : 'ua']?.length > 100 ? props.data.note[i18n.language === 'en' ? 'en' : 'ua'].substr(0, 100) + '...' : props.data.note[i18n.language === 'en' ? 'en' : 'ua']
                                    : props.data.note[i18n.language === 'en' ? 'en' : 'ua']
                            }
                        </p>
                        <div className="row">
                            <a href="/cooperation" className={'btn bg-main text-white mt-3 col-md-6'}>{t('nav.cooperation')}</a>
                            <a href="/cooperation" className={'btn text-secondary mt-3 col-md-6'}>{t('nav.consultation')}</a>
                        </div>
                    </div>

                </div>

            </div>

    );

}

export default Service_card;