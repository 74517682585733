

import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";

function PageNotFound(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'container bg-white p-md-5 p-2 col-md-6 col-12 m-auto'}>

        <div className={'lead text-secondary text-start'}>
            <h5>
                {t('404.text.1')}
            </h5>
            <p>
                {t('404.text.2')}
                <br/>
                {t('404.text.3')}
            </p>
            <a href="/" className={'btn bg-main text-white'}>{t('nav.home')}</a>
        </div>

    </div>);
}

export default PageNotFound;
