
import React from 'react';
import {useTranslation} from "react-i18next";
import ProjectApi from "../../service/api/projects_api";
import Section from "../section";

function ProjectsSection(props) {

    const { t, i18n } = useTranslation()

    return (<div>
        <ProjectApi limit={props.limit} />
    </div>);
}

export default ProjectsSection;