import React from 'react';
import {useTranslation} from "react-i18next";

function PageTemplate(props) {

    const { t, i18n } = useTranslation()

    /*
    * 1481277542470-605612bd2d61
    * 1529333034259-bc9d027df718
    * 1492999104346-cabaa757be8f
    *
    * 1527443224154-c4a3942d3acf - OK
    * */

    //https://images.unsplash.com/photo-1604948501466-4e9c339b9c24?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D
    // https://cdn.wccftech.com/wp-content/uploads/2022/03/hh.jpg
    //https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D

    const styleBg  = {
        backgroundImage: `url("https://images.unsplash.com/photo-1604948501466-4e9c339b9c24?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")`,
        backgroundSize:"cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        // height: "auto"
    }

    return (<div className={'page-header'}>
        {
            window.location.pathname === '/' && <div className={"title-company title-page-header vh-100"}
                                                     style={styleBg}>
                <div className="content-page-header p-2 text-center">
                    <h1 className={'color-main animate__animated animate__slideInUp display-1'}>
                        {props.slogan}
                        <b className={'text-uppercase display-md-3'}></b>
                    </h1>
                    <p className={'lead h6 text-white text-uppercase- animate__animated animate__slideInDown'}>
                        {props.description}
                    </p>
                </div>
            </div>
        }

        {
            props.child
        }
    </div>);
}

export default PageTemplate;
