import React, {useState} from 'react';
import {useTranslation} from "react-i18next";

function Language(props) {

    const { t, i18n } = useTranslation()

    const [language, setLetnguage] = useState('en');

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value)
        setLetnguage(event.target.value);
        console.log('language: ' + event.target.value);
    }

    return (<div>
        <div id="lang-switch">
            <div onChange={changeLanguage}>
                <ul className={'list-unstyled d-flex flex-column-'}>
                    <li className={'p-1'}>
                        <label className="radio-img p-11 flag-language bg-transparent">
                            <input type="radio" value="en" name="language" defaultChecked />
                            <img src="/assets/lang/images/uk.png" alt="English" width="20"/>
                           <span className={props.color}>Eng</span>
                        </label>
                    </li>
                    <li className={'p-1'}>
                        <label className="radio-img p-11 flag-language bg-transparent">
                            <input type="radio" value="ua" name="language" />
                            <img src="/assets/lang/images/ua.png" alt="Ukraine" width="20"/>
                            <span className={props.color}>Укр</span>
                        </label>
                    </li>
                </ul>
            </div>
        </div>
    </div>);
}

export default Language;
