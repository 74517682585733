import React, {useState} from "react";
import './../App.css';
import {useTranslation} from "react-i18next";
import Language from "./language";
import {Container, Nav, NavDropdown} from "react-bootstrap";
import Navbar from "./navbar/navbar";

function Header(props) {
    const { t, i18n } = useTranslation();

    return (
       <div className="container-fluid p-0">
           <header className="header fixed-top">
               <Navbar />
           </header>
       </div>
    );
}

export default Header;
