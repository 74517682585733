import React, {Suspense, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Language from "./language";

import * as FaIcons from "react-icons/fa";
import BASIC_INFORMATION from '../service/information/basic';
import Section from "./section";
const SectionSupport = React.lazy(() => import('../components/section/support_section'));

function Footer() {
    const { t, i18n } = useTranslation();

    // const [service, setService] = useState([]);
    //
    // useEffect(() => {
    //     const data = localStorage.getItem('SERVICES');
    //     if ( data !== null ) setService(JSON.parse(data));
    // }, []);

    return (
        <div>
            {/*<div className="bg-white pt-5">*/}
            {/*    <div className={'container'}>*/}

            {/*        <div className="d-flex flex-wrap">*/}

            {/*            <div className="col-md-6 d-flex flex-wrap align-items-center p-3 pt-0 pb-mb-0 pb-5">*/}

            {/*                <div className="col-md-12 text-start">*/}
            {/*                    <h2 className={'display-4'}>*/}
            {/*                        <b>Track progress your project</b>*/}
            {/*                    </h2>*/}
            {/*                    <br/>*/}
            {/*                    <span className={'lead text-secondary m-0'}>{t('nav.app.download')}</span>*/}
            {/*                    <div className="d-flex text-start mt-3">*/}
            {/*                        <img src="/assets/app/android.svg" alt="android" className={'img-fluid col-md-4 p-2'}/>*/}
            {/*                        <img src="/assets/app/ios.svg" alt="ios" className={'img-fluid col-md-4 p-2'}/>*/}
            {/*                        <div className="col-md-4"></div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}

            {/*            <div className="col-md-6 text-end">*/}
            {/*                <img src="/assets/app/app.png" alt="ios-android-app" className={'img-fluid'}/>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*    </div>*/}
            {/*</div>*/}
            {/*<Suspense fallback={<div>Loading...</div>}><SectionSupport /></Suspense>*/}
            <Section title={''} name={''} child={<div className={'navbar'}>
                <div className="d-flex align-items-center m-auto">
                    <a href="/" className={'text-decoration-none'}>
                        <h2 className={'color-main display-5'}>
                            Growth  <img src="/assets/lang/images/ua.png" alt="Ukraine" width="20"/> Ukraine
                        </h2>
                    </a>
                </div>
            </div>} link={'/'} linkName={''}/>
            <footer>
                <div className={'footer-links'}>

                    <div className="col-md-10 m-auto p-md-0 p-3">
                        <div className="pb-5 d-flex justify-content-between align-items-center pt-5">
                            <div className="col-md-6 text-start">
                                <h3 className={'text-secondary p-'}>{t('nav.online')}</h3>

                                <div className="text-start">

                                    <a id={'footer-phone'} href={'tel.:' + BASIC_INFORMATION.phoneKyivstar} className={'color-main'}>

                                        {BASIC_INFORMATION.phoneKyivstar}

                                    </a>
                                    <br/>
                                    <a id={'footer-email'}  href={
                                        'mailto:' + BASIC_INFORMATION.email + '?subject=Q&A-Footer'
                                    } className={'color-main'}>

                                        {BASIC_INFORMATION.email}

                                    </a>
                                </div>
                                <div className="mt-5">
                                    <div className="row col-md-6">
                                        <a href="/information#privacy-policy" className={'text-secondary col-md-4 col-4'}><span className={'p-0 m-0'}>{t('nav.privacy-policy')}</span></a> <br/>
                                        <a href="/information#term-of-use" className={'text-secondary col-md-4 col-4'}><span className={'p-0 m-0'}>{t('nav.term-of-use')}</span></a> <br/>
                                        <a href="/site-map" className={'text-secondary col-md-4 col-4'}><span className={'p-0 m-0'}>{t('nav.site-map')}</span></a>
                                        <div className="col-6 m-auto d-block d-sm-none pt-5 pb-2">
                                            <Language />
                                        </div>
                                    </div>
                                    {/*<p id={'copyright'} className={'pt-2 text-secondary'}>{t('copyright')} © {(new Date().getFullYear())}.  {t('title')} Group.</p>*/}
                                </div>
                            </div>
                            <div className="col-md-6- d-none d-sm-block text-end flex-column align-content-between">
                                <h3 className={'text-secondary p- '}>{t('nav.follow-us')}</h3>
                                <div className="links text-start p- pt-md-2">

                                    {/*<a href={BASIC_INFORMATION.socialInstagram} className={'text-secondary'}> <FaIcons.FaInstagram /> <span>Instagram</span></a> <br/>*/}
                                    {/*<a href={BASIC_INFORMATION.socialFacebook} className={'text-secondary'}> <FaIcons.FaFacebook /> <span>Facebook</span></a> <br/>*/}
                                    {/*<a href={BASIC_INFORMATION.socialLinkedIn} className={'text-secondary'}> <FaIcons.FaLinkedinIn /> <span>LinkedinIn</span></a><br/>*/}
                                    <a href={BASIC_INFORMATION.socialTelegram} className={'text-secondary'}> <FaIcons.FaTelegram /> <span>Telegram</span></a><br/>
                                    <a href={BASIC_INFORMATION.socialTelegram} className={'text-secondary'}> <FaIcons.FaWhatsapp /> <span>WhatsApp</span></a><br/>
                                    <a href={BASIC_INFORMATION.socialTelegram} className={'text-secondary'}> <FaIcons.FaViber /> <span>Viber</span></a><br/>

                                </div>

                                <div className="pt-2 mt-5 text-end">
                                    <Language />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="container-fluid bg-white">
                       <div className="container d-flex align-items-center text-start">
                           <div className="col-md-6 p-1">
                               <p id={'copyright'} className={'pb-1 pt-3 text-start text-secondary'}>{t('copyright')} © {(new Date().getFullYear())}.  {t('title')} Group.</p>
                           </div>
                           <div className="col-md-6">

                           </div>

                       </div>
                    </div>

                </div>
            </footer>
        </div>
    );
}

export default Footer;