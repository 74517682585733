

import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import BASIC_INFORMATION from "../service/information/basic";

function PageCareer(props) {

    const { t, i18n } = useTranslation()

    return (<div>
        <Section title={''} name={'career'} child={

            <div>
                <p className={'display-6 text-secondary'}>
              {t('nav.vacancy.description')} <br/>
                <a id={'career-link'} href={
                    'mailto:' + BASIC_INFORMATION.email + '?subject=VacancyCV'
                } className={'text-decoration-none color-main'} ><b>{BASIC_INFORMATION.email}</b></a>
                </p>
            </div>
        }/>
    </div>);
}

export default PageCareer;
