import React from 'react'
import Section from "../components/section";
import ServiceSection from "../components/section/service_section";

function ServicesPage(props) {

    return (<div>
        <Section title={''} name={'services'} child={<ServiceSection type={'all'} />}/>
    </div>);
}

export default ServicesPage;
