import React, {useEffect, useState} from 'react';
import {Phone} from "react-telephone";
import {withTranslation} from "react-i18next";
import axios from "axios";
import {BACK_END_URL, REDIRECT_FORM_RESULT} from "../service/api/config";
import Reaptcha from "reaptcha";
import useAnalyticsEventTracker from "../service/g-analytics/useAnalyticsEventTracker";

class CooperationForm extends React.Component {

    constructor() {
        super();
        this.state = {
            input: {},
            services: {},
            errors: {},
            verified: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.getServices = this.getServices.bind(this);
    }

    async getServices(){
        let data = axios.get(BACK_END_URL + 'get-services').then(function (response){
            console.log(response.data);
            return response.data.data;
        }).catch(error => {
            console.log(error);
        })

        window.localStorage.setItem("services", JSON.stringify(data));
        this.setState({
            services: data
        });
    }

    componentDidMount() {
        console.log('Init CooperationForm');

        if(localStorage.getItem('services')){
            this.setState({
                services: JSON.parse(localStorage.getItem('services'))
            });
        }else{
            this.getServices();
        }
    }

    handleChange(event) {
        let input = this.state.input;
        input[event.target.name] = event.target.value;
        console.log(input);
        this.setState({
            input
        });
    }

    reCaptchaChange(value){
        console.log("Captcha value:", value);
        this.setState({
            recaptcha: value.toString()
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if(this.validate()){
            console.log(this.state);

            let input = {};
            input["name"] = "";
            input["email"] = "";
            input["phone"] = "";
            input["note"] = "";
            input["service_id"] = "";
            this.setState({input:input, verified: false});

            // send form data
            this.sendFrom();
        }
    }

    sendFrom() {

        let input = this.state.input;

        console.log(this.state.recaptcha);

        axios.post(
            BACK_END_URL + 'offer',
            {
                "name": this.state.input.name,
                "phone": this.state.input.phone,
                "email": this.state.input.email,
                "service_id": this.state.input.service_id,
                // "business": business,
                "g-recaptcha-response": this.state.verified,
            },{
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
            }
        ).then(result => {
            console.log(result);
            window.location = REDIRECT_FORM_RESULT;
            console.log('Form is submited');
            const gaEventTracker = useAnalyticsEventTracker('Cooperation');
            gaEventTracker('offer');
        }).catch(error => {
            console.log(error);
        })
    }

    validate(){
        let input = this.state.input;
        let errors = {};
        let isValid = true;

        if (!input["name"]) {
            isValid = false;
            errors["name"] = "form.error.name";
        }

        if (!input["email"]) {
            isValid = false;
            errors["email"] = "form.error.name";
        }

        if (typeof input["email"] !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["email"])) {
                isValid = false;
                errors["email"] = "Please enter valid email address.";
            }
        }

        if (!input["phone"]) {
            isValid = false;
            errors["phone"] = "form.error.phone";
        }

        if (typeof input["phone"] !== "undefined") {

            console.log(input["phone"]);
            var phone = '0' + input["phone"].replace('(', '').replace(')', '').replace(' ', '').replace(' ', '').replace(' ', '');
            console.log(phone);

            var pattern = new RegExp(/^[0-9\b]+$/);

            if (!pattern.test(phone)) {
                isValid = false;
                errors["phone"] = "Please enter only number.";
            }else if(phone.length != 10){
                isValid = false;
                errors["phone"] = "Please enter valid phone number.";
            }
        }

        // if (!input["comment"]) {
        //     isValid = false;
        //     errors["comment"] = "Please enter your comment.";
        // }

        if (!input["service_id"]) {
            isValid = false;
            errors["service"] = "form.error.service";
        }

        this.setState({
            errors: errors
        });

        return isValid;
    }

    onVerify = recaptchaResponse => {
        this.setState({
            verified: true
        });
    };

    render() {

        const { t } = this.props;

        return (
            <div className={'pt-4'}>
                <form onSubmit={this.handleSubmit}>

                    <div className="row">
                        <div className="form-group col-md-6 mt-3">
                            <label htmlFor="name">{t('form.name')}</label>
                            <input
                                type="text"
                                name="name"
                                value={this.state.input.name}
                                onChange={this.handleChange}
                                className="form-control"
                                placeholder="John Doe"
                                id="name"/>

                            <div className="text-danger">{t(this.state.errors.name)}</div>
                        </div>

                        <div className="form-group mt-3 col-md-6">
                            <label htmlFor="email">{t('form.email')}
                                {/*<span className={'m-0'}>({t('form.optional')})</span>*/}
                            </label>
                            <input
                                type="text"
                                name="email"
                                value={this.state.input.email}
                                onChange={this.handleChange}
                                className="form-control"
                                placeholder="example@mail.com"
                                id="email"/>

                            <div className="text-danger">{this.state.errors.email}</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-6 mt-3">
                            <label htmlFor="Phone">{t('form.phone')}</label>
                            <Phone defaultCountry={'ua'}>
                                <div className="row">
                                    <div className="p-2 pt-0 col-md-6">
                                        <Phone.Country className={'form-control'} name={'phoneCode'} />
                                    </div>

                                    <div className="p-2 pt-0 col-md-6">
                                        <Phone.Number value={this.state.input.phone} onChange={this.handleChange} placeholder={'99 001 01 01'} className={'form-control'} name={'phone'} />
                                    </div>

                                </div>
                            </Phone>

                            <div className="text-danger">{t(this.state.errors.phone)}</div>
                        </div>

                        <div className="form-group col-md-6 mt-3">
                            <label htmlFor="service_id">{t('form.service')}</label>
                            <select name="service_id" id="service_id" className={'form-control'} onChange={this.handleChange}>
                                <option value="0" key={'0'} disabled selected={true}>- {t('form.select')} -</option>
                                {Object.values(this.state.services).map((item, i) =>  {
                                   return (
                                       <option key={i} value={item.id} className={''}> { item.name[this.props.lang]}</option>
                                   );
                                })}
                            </select>
                            <div className="text-danger">{t(this.state.errors.service)}</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <label htmlFor="service">ReCaptcha</label>
                            <Reaptcha
                                ref={e => (this.state.recaptcha = e)}
                                sitekey="6LdijCkkAAAAAEougRGPzsz2vnW_KFQxDmtb8fAH"
                                onVerify={this.onVerify}
                            />
                            <br/>
                            <input type="submit" value={t('form.send')} disabled={!this.state.verified} className="btn bg-main text-white text-uppercase w-100"/>
                        </div>
                        <div className="col-md-6 mt-3">
                            <label htmlFor="note">{t('form.about')} <span className={'m-0'}>({t('form.optional')})</span></label>
                            <textarea
                                name="note"
                                value={this.state.input.note}
                                onChange={this.handleChange}
                                placeholder=""
                                className="form-control"
                                cols="30" rows="5"
                            />

                            <div className="text-danger">{this.state.errors.note}</div>
                        </div>
                    </div>


                </form>
            </div>
        );
    }
}

export default withTranslation()(CooperationForm);