import Footer from "./components/footer";
import Header from "./components/header";
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from "./pages/home";
import OfferPage from "./pages/offer";
import {Routes, Route, Link} from "react-router-dom";
import PageNotFound from "./pages/404";
import ServicesPage from "./pages/services";
import ProjectsPage from "./pages/projects";
import ContactsPage from "./pages/contacts";
import BackToTop from "react-back-to-top-button";
import ThankYouPage from "./pages/thank-you";
import React, {useEffect, useState} from "react";
import PageMapSite from "./pages/map-site";
import PageInformation from "./pages/information";
import PageSingle from "./pages/single";
import 'animate.css';
import {CookieConsent} from "react-cookie-consent";
import {useTranslation} from "react-i18next";

import ReactGA from 'react-ga';
import * as FaIcons from "react-icons/fa";
import PageTemplate from "./components/tamplate/page";
import PageTeam from "./pages/team";
import PageCareer from "./pages/career";
import PageSupport from "./pages/support";

const TRACKING_ID = "UA-255343396-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {

    // const [services, setServices] = useState('');
    //
    // useEffect(()=>{
    //     setServices(localStorage.getItem("services"))
    //     console.log('services' + services);
    // })

    const { t, i18n } = useTranslation()

    return (
        <div className="App">
            <Header/>
            <main>
                <Routes>
                    <Route index exact path={'/'} element={<PageTemplate slogan={t('slogan.title')} description={t('slogan.description')} child={<HomePage/>} />}/>

                    <Route exact path={'/cooperation'} element={
                        <PageTemplate slogan={t('nav.cooperation')} description={t('slogan.description.cooperation')} child={<OfferPage/>} />
                    }/>
                    <Route exact path={'/services'} element={
                        <PageTemplate slogan={t('nav.services')} description={t('slogan.description.services')} child={ <ServicesPage/>} />
           }/>
                    <Route exact path={'/projects'} element={
                        <PageTemplate slogan={t('nav.projects')} description={t('slogan.description.projects')} child={ <ProjectsPage/>} />
       }/>
                    <Route path={'/project/:slug'} element={<PageSingle/>}/>
                    <Route exact path={'/contacts'} element={<ContactsPage/>}/>
                    <Route exact path={'/thank-you'} element={ <ThankYouPage/>}/>
                    <Route exact path={'/support'} element={ <PageTemplate slogan={t('nav.donate')} description={t('ua.support')} child={ <PageSupport/>} />}/>
                    <Route exact path={'/site-map'} element={ <PageTemplate slogan={t('nav.site-map')} description={t('nav.links')} child={ <PageMapSite/>} />}/>
                    <Route exact path={'/information'} element={ <PageTemplate slogan={t('nav.info')} description={t('nav.privacy-policy') + ' & ' + t('nav.term-of-use')} child={ <PageInformation/>} />}/>

                    <Route exact path={'/team'} element={ <PageTemplate slogan={t('nav.team')} description={''} child={ <PageTeam/>} />}/>
                    <Route exact path={'/career'} element={ <PageTemplate slogan={t('nav.vacancy')} description={t('nav.career')} child={ <PageCareer/>} />}/>

                    <Route exact path={'/mobile-app'} element={ <PageTemplate slogan={t('nav.mobile.app')} description={t('nav.career')} child={ <div>
                        <h1 className="mt-5">
                            We make app better!
                        </h1>
                    </div>} />}/>

                    <Route path="*" element={ <PageTemplate slogan={t('404.slogan')} description={t('404.description')} child={ <PageNotFound />} />}/>
                </Routes>
            </main>
            <BackToTop
                showOnScrollUp
                showAt={100}
                speed={1500}
                easing="easeInOutQuint"
            >
                <span>
                    <FaIcons.FaArrowUp class={'bg-white color-main border-3 p-2 shadow rounded'} />
                </span>
            </BackToTop>
            <CookieConsent
                location="bottom"
                buttonText="OK"
                cookieName="myCookieName"
                expires={150}
                containerClasses="col-lg-12 d-flex align-items-center bg-transparent shadow"
                contentClasses="p-1"
                buttonClasses={"shadow"}
                buttonStyle={{borderRadius: "10px", background: "white", padding: "25px", color: "#1e1e1e"}}
            >
                <div className="p-3 bg-main border-white rounded-4">
                    { t('cookie') }.
                    <br/>
                    <span>
                        <a href="/information">{ t('cookie.info') }.</a>
                    </span>
                </div>
            </CookieConsent>

            <Footer/>
        </div>
    );
}

export default App;
