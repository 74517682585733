

import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Section from "../components/section";
import {useParams} from "react-router-dom";
import axios from "axios";
import {BACK_END_URL} from "../service/api/config";
import Fancybox from "../service/image/fancybox";

function PageSingle(props) {

    const { t, i18n } = useTranslation()

    let {slug} = useParams();

    const [project, setProject] = useState({
        "id": 0,
            "name": {
            "en": "English",
            "ua": "Ukraine"
        },
        "description": {
            "en": "English",
                "ua": "Ukraine",
        },
        "image": "http://127.0.0.1:8000/storage/files/portfolio/image.png",
            "service": {
            "id": 4,
                "name": {
                "en": "English",
                    "ua": "Ukraine"
            },
            "note": {
                "en": "English",
                "ua": "Ukraine"},
            "price": {
                "USD": 200
            },
            "image": null,
                "slug": "name",
                "url": "/services/name"
        },
        "content": "",
            "links": {
            "production_link": "https://example.com",
                "repository_url": "https://example.com"
        },
        "files": [
            {
                "url": "https://api.growthukraine.com/storage/files/file.png",
                "name": "file-1.png",
                "name_file_url": "file-1.png"
            },
        ],
            "slug": "title-example-slug"
    });
    const [load, setLoader] = useState(false);

    useEffect(()=>{
        setLoader(true);
        axios.get(BACK_END_URL + 'project/' + slug).then(function (response){

            setProject(response.data.data);
            console.log(project);

        }).catch(error => {
            console.log(error);
        })
        setLoader(false);
    }, []);

    if(load){return <div>Loading...</div>}

    return (<div>
        <section>
            <div className="image-banner-header shadow">
                <img src={project.image} alt="image-banner" className={'img-single-page'}/>
            </div>
        </section>
        <Section title={''} name={slug} child={<div>
            <div className="row d-flex justify-content-between">
                <div className="information col-md-6 col-12">
                    <h1>{project.name[i18n.language]}</h1>

                    <p className={'mt-5 lead'}>{project.description[i18n.language]}</p>

                    <span className={'text-secondary m-0'}>{ project?.content !== null && (<div>{t('nav.task')}</div>)}</span>
                    <p className={'p-2 lead'}>
                        { project?.content === null ? '' : JSON.stringify(project.content)[i18n.language]}
                    </p>


                    <div className="d-flex pb-5 flex-row-reverse">
                        <a href="/cooperation" className={'btn bg-main text-white mt-3 col-md-6 col-6'}>{t('nav.cooperation')}</a>
                        <a href="/cooperation" className={'btn text-secondary mt-3 col-md-6 col-6'}>{t('nav.consultation')}</a>
                    </div>
                </div>
                <div className="files col-md-6 col-12 text-end">
                    <span className="text-secondary p-0 m-1 display-6">
                        #{project.service?.name[i18n.language] ?? 'NAME'}
                    </span>
                    <Fancybox options={{ infinite: false }}>
                       <div className="d-flex flex-wrap pt-5">
                           {
                               project.files === null ? <br/> :
                                   project.files.map((file) => <div className="file col-md-6" data-fancybox="gallery" data-src={file.url}>
                                       <img src={
                                           file.url
                                       } alt={'file_' + file.url} className={'card-project-single-img rounded mb-2 shadow'}/>
                                   </div>)
                           }
                       </div>
                    </Fancybox>

                </div>
            </div>

        </div>} link={'#'} linkName={''}/>
    </div>);
}

export default PageSingle;